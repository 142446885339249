import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { firstValueFrom } from 'rxjs';
import { jwtDecode } from 'jwt-decode';
import { UserToken } from '../interfaces/user-token.interface';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  token!: string;
  account!: any;
  private validGroupId = '5e04753a-4007-4698-85c8-5b8eb0d12e46';

  constructor(private authService: MsalService, private router: Router) {}

  getToken() {
    return this.token;
  }

  async getAccessTokenDirectly() {
    await this.getAccount();
    if (this.account && this.isValidGroup()) {
      // Verifica si el usuario pertenece al tenant válido
      await firstValueFrom(
        this.authService.acquireTokenSilent({
          account: this.account,
          scopes: ['user.read'],
        })
      )
        .then((response: AuthenticationResult) => {
          this.token = response.accessToken;
        })
        .catch((error) => {
          this.token = '';
        });
    } else {
      console.error('Access denied: Invalid tenant');
      this.router.navigate(['/access-denied']);
      this.token = '';
    }
  }

  async getAccount() {
    this.account = await this.authService.instance.getActiveAccount();
  }

  getUserData(): UserToken {
    return jwtDecode(this.token);
  }

  userHasRole(requiredRoles: string[]): boolean {
    const userRoles: string[] = this.account?.idTokenClaims['roles'] || [];
    return requiredRoles.some((role) => userRoles.includes(role));
  }

  private isValidGroup(): boolean {
    // Verifica si el tenantId del usuario coincide con el tenant permitido
    const tenantGroups = this.account?.idTokenClaims.groups || [];
    const res = tenantGroups.some(
      (group: string) => group === this.validGroupId
    );
    console.log('isValidGroup', res);

    return res;
  }
}
